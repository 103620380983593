import React from "react";

import Hero from "../components/commons/Hero";
import Page from "../components/commons/Page";
import SpaceAnim from "../components/commons/SpaceAnim";
import Button from "../components/commons/Button";

const NotFoundPage = () => (
  <Page title="404: Not found">
    <Hero fullscreen>
      <Hero.Background>
        <SpaceAnim />
      </Hero.Background>
      <Hero.Content sx={{ pointerEvents: "none" }}>
        <Hero.Title>Looks like You’re lost</Hero.Title>

      </Hero.Content>
    </Hero>
  </Page>
);

export default NotFoundPage;
